import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Podolog zadba o zdrowie i piękno Twoich stóp, pedicure medyczny`}
        description={`Profesjonalne usługi podologa, pedicure leczniczy. Oferujemy podstawowy zabieg podologiczny, pielęgnację stopy cukrzycowej, usuwanie odcisków, wygładzanie pięt.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">
                Zabiegi wykonywane przez podologa
              </h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Podolog jest specjalistą zajmującym się pielęgnacją i leczeniem
                stóp. Zdrowe i zadbane stopy są niezwykle ważną częścią ciała
                każdego człowieka, ponieważ od nich zależy m.in. prawidłowa
                postawa, właściwe rozłożenie ciężaru, utrzymywanie równowagi i
                kondycja całego kręgosłupa. Niestety, niewygodne obuwie,
                nierówne podłoże, czy praca wymuszająca pewne pozycje nie
                sprzyjają ich komfortowi.
              </p>
              <p className="offer-item-description">
                Ratunkiem dla obolałych i zaniedbanych stóp może okazać się
                wizyta u podologa w Balicka Clinic. Oprócz podstawowego zbiegu
                polegającego na prawidłowym skróceniu płytki paznokciowej,
                oczyszczeniu wałów paznokciowych, usunięciu zrogowaceń,
                pomalowaniu płytki paznokciowej, podolog nakłada profesjonalne
                pielęgnacyjne dermoceutyki zapobiegające problemom skórnym.
                Wykona również masaż stóp, który przyniesie ulgę napiętym
                mięśniom, pobudzi krążenie i usuwanie toksyn z organizmu, a
                nawet obniży poziom stresu.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Czym dokładnie zajmuje się podolog?
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Podstawowy zabieg podologiczny, nazywany też pedicurem
                medycznym, zaczyna się od postawienia diagnozy, co dokładnie
                dolega stopom. Następnie dokonuje się dezynfekcji, opracowania
                paznokci, wygładzenia skóry na podeszwie i całej stopie,
                nałożenia kosmetyków. W trakcie pracy podolog posługuje się
                specjalnym urządzeniem zwanym frezarką podologiczną, a do
                konkretnych czynności używa dedykowanych frezów.
              </p>
              <div className="offer-item-description">
                Oprócz podstawowego zabiegu pielęgnacyjnego nasza specjalistka w
                Balicka Clinic zajmuje się także leczeniem schorzeń i
                łagodzeniem dolegliwości związanych ze stopami. Można wśród nich
                wyróżnić:
                <ul>
                  <li>
                    usuwanie odcisków, modzeli, nagniotków (częsty problem Pań
                    chodzących w szpilkach);
                  </li>
                  <li>wygładzanie pękających pięt;</li>
                  <li>
                    zapobieganie wrastaniu paznokci (w tym zakładanie
                    profesjonalnych klamer);
                  </li>
                  <li>
                    leczenie infekcji paznokci (grzybica, zakażenia bakteryjne,
                    brodawki wirusowe);
                  </li>
                  <li>
                    rekonstrukcję płytki paznokciowej; opracowanie rany
                    cukrzycowej i zakładanie opatrunków odciążających.
                  </li>
                </ul>
              </div>

              <p className="offer-item-description">
                Podolog zaleci również odpowiednie metody domowej pielęgnacji i
                wskaże rozwiązania pomocne przy konkretnej dolegliwości. W
                Balicka Clinic dbamy, aby stopy naszych Klientów były nie tylko
                piękne, ale przede wszystkim zdrowe. Przywracamy swobodę ruchu i
                pełną aktywność, przy jednoczesnej dbałości o walory estetyczne.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Zdjęcia</p>
              <div className="separator-line-horizontal"></div>
              <div class="offer-item-images">
                <img
                  src="../img/offer/podologia/images/1.jpg"
                  alt="Zdjęcie stopy"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/podologia/images/2.jpg"
                  alt="Zdjęcie stopy"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/podologia/images/3.jpg"
                  alt="Zdjęcie stopy"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/podologia/images/4.jpg"
                  alt="Zdjęcie stopy"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/podologia/images/5.jpg"
                  alt="Zdjęcie stopy"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/podologia/images/6.jpg"
                  alt="Zdjęcie stopy"
                  className="offer-item-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
